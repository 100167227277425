import type { FC } from 'react';

import type { HomeManagingCompany } from '@api/generated';

import { applyTypograf } from '@utils/typograf';

import { PromoBanner } from '@components/common/PromoBanner/PromoBanner';

export const HomePromoBanner: FC<{
    data: HomeManagingCompany;
}> = ({ data: { title, description, image, url } }) => (
    <PromoBanner
        title={title}
        text={description === null ? '' : applyTypograf(description)}
        image={image.landscape}
        portraitImage={image.portrait}
        links={[
            {
                variant: 'transparentOutline',
                href: url,
                children: 'На сайт УК',
            },
        ]}
        isTextHiddenOnMobile
    />
);
