import * as Accordion from '@radix-ui/react-accordion';

import type { FC } from 'react';

import type { HomePurchaseMethod } from '@api/generated';

import { applyTypograf } from '@utils/typograf';

import { Container } from '@components/common/Container/Container';
import { CardAccordion } from '@components/home/CardAccordion/CardAccordion';

import styles from './PurchasePropertySection.module.scss';

export const PurchasePropertySection: FC<{
    items: HomePurchaseMethod[];
}> = ({ items }) => (
    <Container>
        <h2 className={styles.heading}>Покупка недвижимости</h2>

        <Accordion.Root type="single" className={styles.grid} collapsible>
            {items.map((item, index) => (
                <CardAccordion
                    key={index}
                    index={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    description={applyTypograf(item.description)}
                />
            ))}
        </Accordion.Root>
    </Container>
);
