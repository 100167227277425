import type { FC, ReactNode, Ref, SyntheticEvent } from 'react';

import type { EitherSlideVideo, HomeHeroSlideButton } from '@api/generated';

import { ButtonLink } from '@components/common/Button/Button';

import styles from './HeroCarouselVideoSlide.module.scss';

export type HeroCarouselVideoSlideClasses = Classes<'video' | 'data' | 'title' | 'button'>;

export type HeroCarouselVideoSlideProps = Readonly<{
    video: EitherSlideVideo;
    videoRef: Ref<HTMLVideoElement>;
    classes: HeroCarouselVideoSlideClasses;
    onError: () => void;
    onTimeUpdate: (currentTime: number) => void;
    onEnded: () => void;
    onCanPlay: () => void;
    button: HomeHeroSlideButton | null;
    title: ReactNode | null;
}>;

export const HeroCarouselVideoSlide: FC<HeroCarouselVideoSlideProps> = (props) => {
    const { video, videoRef, classes, onError, onTimeUpdate, onEnded, onCanPlay, button, title } =
        props;

    const hasTitle = title !== null;
    const hasButton = button !== null;

    const handleTimeUpdate = (event: SyntheticEvent<HTMLVideoElement>) => {
        if (event.target instanceof HTMLVideoElement) {
            onTimeUpdate(event.target.currentTime);
        }
    };

    return (
        <div className={styles.root}>
            <video
                ref={videoRef}
                className={classes.video}
                controls={false}
                muted
                playsInline
                disablePictureInPicture
                disableRemotePlayback
                preload="auto"
                onError={onError}
                onTimeUpdate={handleTimeUpdate}
                onEnded={onEnded}
                onCanPlay={onCanPlay}
            >
                <source
                    src={video.portraitMp4Av1OpusUrl}
                    type="video/mp4; codecs=av01.0.05M.08,opus"
                    media="(orientation: portrait)"
                />
                <source
                    src={video.portraitMp4HevcAacUrl}
                    type="video/mp4; codecs=hvc1"
                    media="(orientation: portrait)"
                />
                <source
                    src={video.portraitMp4H264AacUrl}
                    type="video/mp4; codecs=avc1.4D401E,mp4a.40.2"
                    media="(orientation: portrait)"
                />
                <source
                    src={video.landscapeMp4Av1OpusUrl}
                    type="video/mp4; codecs=av01.0.05M.08,opus"
                    media="(orientation: landscape)"
                />
                <source
                    src={video.landscapeMp4HevcAacUrl}
                    type="video/mp4; codecs=hvc1"
                    media="(orientation: landscape)"
                />
                <source
                    src={video.landscapeMp4H264AacUrl}
                    type="video/mp4; codecs=avc1.4D401E,mp4a.40.2"
                    media="(orientation: landscape)"
                />
            </video>
            <div className={classes.data}>
                {hasTitle && <h2 className={classes.title}>{title}</h2>}
                {hasButton && (
                    <ButtonLink
                        href={button.url}
                        variant="transparentOutline"
                        className={classes.button}
                    >
                        {button.text}
                    </ButtonLink>
                )}
            </div>
        </div>
    );
};
