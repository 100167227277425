import type { FC } from 'react';

import type { HomeAboutCompany } from '@api/generated';

import { makeAboutPageRoute } from '@utils/route';
import { applyTypograf } from '@utils/typograf';

import { ButtonLink } from '@components/common/Button/Button';
import { Container } from '@components/common/Container/Container';

import styles from './AboutSection.module.scss';

export const AboutSection: FC<{ data: HomeAboutCompany }> = ({ data }) => (
    <Container>
        <div className={styles.root}>
            <h3 className={styles.title}>{data.title}</h3>

            <p className={styles.text}>{applyTypograf(data.description)}</p>

            <ButtonLink variant="transparentOutline" href={makeAboutPageRoute()}>
                Подробнее о компании
            </ButtonLink>
        </div>
    </Container>
);
