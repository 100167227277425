import type { Image, ImagePair, ImageSet, ImageUrl } from '@api/generated';
import { ImageFormat } from '@api/generated';

const mockEmptyImageUrl: ImageUrl = {
    format: ImageFormat.Jpeg,
    url: '',
};

const mockEmptyImage: Image = {
    urls: [mockEmptyImageUrl],
    width: 0,
    height: 0,
};

const mockEmptyImagePair: ImagePair = [mockEmptyImage, mockEmptyImage];

export const mockEmptyImageSet: ImageSet = {
    web: {
        small: mockEmptyImagePair,
        medium: mockEmptyImagePair,
        large: mockEmptyImagePair,
        blur: mockEmptyImageUrl,
    },
};
