import { allSettled, fork, serialize } from 'effector';

import { fetchGlobalDataFx } from '@stores/global-data/server-side';
import { fetchHomeDataFx } from '@stores/home/server-side';

import { getServerSidePropsWithErrorHandling } from '@api/client/bff';

import { throwFirstFailed } from '@utils/server-side';

import { Home } from '@views/Home/Home';

export const getServerSideProps = getServerSidePropsWithErrorHandling(async (context) => {
    const {
        req: { headers },
    } = context;

    const scope = fork();

    const results = await Promise.all([
        allSettled(fetchGlobalDataFx, { scope, params: { pageKind: 'server' } }),
        allSettled(fetchHomeDataFx, { scope, params: { headers } }),
    ]);

    throwFirstFailed(results);

    return { props: { pageProps: {}, serializedScope: serialize(scope) } };
});

export default Home;
