import type { YMapLocationRequest } from '@yandex/ymaps3-types';

export type Coordinate = [number, number];

type Bounds = [Coordinate, Coordinate];

export type MapDim = {
    width: number;
    height: number;
};

const WORLD_DIM: MapDim = { width: 256, height: 256 };
const ZOOM_MAX = 19;

const latRad = (lat: number) => {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
};

const zoom = (mapPx: number, worldPx: number, fraction: number) =>
    Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);

const getBoundsZoomLevel = ([ne, sw]: Bounds, mapDim: MapDim): number => {
    const latFraction = (latRad(ne[1]) - latRad(sw[1])) / Math.PI;
    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);

    const lngDiff = ne[0] - sw[0];
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX) - 1;
};

export const getCenterAndZoom = (
    coordinates: Coordinate[],
    mapDim: MapDim,
): YMapLocationRequest => {
    const latitudes = coordinates.map((coord) => coord[0]);
    const longitudes = coordinates.map((coord) => coord[1]);
    return {
        center: [
            coordinates.reduce((sum, coord) => sum + coord[0], 0) / coordinates.length,
            coordinates.reduce((sum, coord) => sum + coord[1], 0) / coordinates.length,
        ],
        zoom: getBoundsZoomLevel(
            [
                [Math.max(...latitudes), Math.max(...longitudes)],
                [Math.min(...latitudes), Math.min(...longitudes)],
            ],
            mapDim,
        ),
    };
};
