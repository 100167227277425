import { makeProjectsRoute } from '@utils/route';

import { ButtonLink } from '@components/common/Button/Button';
import { ProjectCard } from '@components/common/ProjectCard/ProjectCard';
import type { ModeContent } from '@components/common/ProjectsList/lib';

import styles from './AsList.module.scss';

export const AsList: ModeContent = ({ projects, showInList = projects.length }) => (
    <>
        <div className={styles.list}>
            {projects.slice(0, showInList).map((project) => (
                <ProjectCard key={project.id} {...{ project }} />
            ))}
        </div>
        {showInList < projects.length && (
            <div className={styles.buttonContainer}>
                <ButtonLink variant="transparentOutline" href={makeProjectsRoute()}>
                    Смотреть все
                </ButtonLink>
            </div>
        )}
    </>
);
