import type { FC } from 'react';

import { type ProjectPreview } from '@api/generated';

import { makeProjectPlanRoute } from '@utils/project';
import { makeProjectRoute } from '@utils/route';
import { displayRublesCompact } from '@utils/strings';

import { ButtonLink } from '@components/common/Button/Button';
import { Picture } from '@components/common/Picture/Picture';

import styles from './ProjectCard.module.scss';

import { CarIcon } from '@assets/icons';

export type ProjectCardProps = Readonly<{ project: ProjectPreview }>;

export const ProjectCard: FC<ProjectCardProps> = (props) => {
    const {
        project: {
            image,
            slug,
            title,
            description,
            address,
            nearestBusStop,
            minPriceRub,
            status,
            availableApartmentsCount,
            planKind,
        },
    } = props;

    const planRoute = makeProjectPlanRoute(slug, planKind);

    return (
        <div className={styles.root}>
            <Picture sources={image} alt={title} />

            <div className={styles.content}>
                <p className={styles.name}>
                    {title}
                    {minPriceRub !== null && (
                        <span className={styles.price}>
                            &nbsp;от&nbsp;
                            {displayRublesCompact(minPriceRub)}
                        </span>
                    )}
                </p>

                <p className={styles.description}>{description}</p>

                <address className={styles.addressBlock}>
                    <span className={styles.address}>{address}</span>
                    {nearestBusStop !== null && (
                        <span className={styles.travelTime}>
                            <span className={styles.travelTimeSeparator}>·</span>
                            <CarIcon className={styles.travelTimeIcon} />
                            {nearestBusStop.travelTimeMinutes !== null
                                ? `${nearestBusStop.travelTimeMinutes} мин до`
                                : 'возле'}
                            &nbsp;{nearestBusStop.title}
                        </span>
                    )}
                </address>
                <div className={styles.buttonsBlock}>
                    {availableApartmentsCount > 0 && (
                        <ButtonLink
                            href={planRoute}
                            variant="darkBlue"
                            className={styles.apartmentsButton}
                        >
                            Смотреть квартиры
                        </ButtonLink>
                    )}
                    <ButtonLink
                        href={makeProjectRoute(slug)}
                        variant="transparentOutline"
                        className={styles.projectButton}
                    >
                        О проекте
                    </ButtonLink>
                </div>
            </div>

            <div className={styles.badge}>{status}</div>
        </div>
    );
};
