import clsx from 'clsx';

import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import { AsList } from '@components/common/ProjectsList/components/AsList/AsList';
import { AsMap } from '@components/common/ProjectsList/components/AsMap/AsMap';
import type {
    ModeContent,
    ProjectsListProps as ProjectsListPropsBase,
    ViewMode,
} from '@components/common/ProjectsList/lib';

import styles from './ProjectsList.module.scss';

const modeByKind: Record<'list' | 'map', { label: ReactNode; content: ModeContent }> = {
    list: { label: 'Списком', content: AsList },
    map: { label: 'На карте', content: AsMap },
};

export type ProjectsListProps = Readonly<ProjectsListPropsBase & { className?: string }>;

export const ProjectsList: FC<ProjectsListProps> = (props) => {
    const { className, ...rest } = props;

    const [viewMode, setViewMode] = useState<ViewMode>('list');

    const { content: Content } = modeByKind[viewMode];

    return (
        <div className={clsx(styles.root, className)}>
            <div className={styles.tabs}>
                {Object.entries(modeByKind).map(([key, { label }]) => (
                    <button
                        className={clsx(styles.tab, key === viewMode && styles.isActive)}
                        type="button"
                        key={key}
                        onClick={() => {
                            setViewMode(key as ViewMode);
                        }}
                    >
                        {label}
                    </button>
                ))}

                <div className={styles.activeTabIndicator} aria-hidden />
            </div>
            <Content {...rest} />
        </div>
    );
};
