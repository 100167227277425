import type { NEA } from '@feip-internal/fp-ts';
import { A } from '@feip-internal/fp-ts';

import type { HomeStory, HomeStoryStep } from '@api/generated';

export type HomeStoryWithSteps = Pick<HomeStory, 'id' | 'poster'> &
    Readonly<{ steps: NEA.NonEmptyArray<HomeStoryStep> }>;

export const doesStoryHaveSteps = (story: HomeStory): story is HomeStoryWithSteps =>
    A.isNonEmpty(story.steps);
