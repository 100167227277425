/* eslint-disable @typescript-eslint/no-shadow */
import { z } from 'zod';

import { IO } from '@feip-internal/fp-ts';
import {
    Either as E,
    F,
    IOEither as IOE,
    N,
    O,
    Predicate as P,
    RA,
    RM,
    ReadonlyRecord as RR,
    ReadonlySet as RS,
    S,
    SG,
} from '@feip-internal/fp-ts';

import { trackException } from '@utils/sentry';

export type MarkStoryStepAsViewedEvent = Readonly<{
    storyId: number;
    stepId: number;
}>;

const viewedStorySlidesStoredValueSchema = z.record(z.array(z.number()).readonly()).readonly();

export type ViewedStorySlidesStoredValue = z.infer<typeof viewedStorySlidesStoredValueSchema>;

const viewedStorySlidesLocalStorageKey = 'viewedStorySlides';

export const loadViewedStorySlides: IO.IO<ReadonlyMap<number, ReadonlySet<number>>> = () =>
    F.pipe(
        localStorage.getItem(viewedStorySlidesLocalStorageKey),
        E.fromNullable(new Error(`${viewedStorySlidesLocalStorageKey} key not found`)),
        E.chain((value) => E.tryCatch(() => JSON.parse(value) as unknown, F.identity)),
        E.chain((value) =>
            E.tryCatch(() => viewedStorySlidesStoredValueSchema.parse(value), F.identity),
        ),
        E.chainW((value) =>
            F.pipe(
                value,
                RR.foldMapWithIndex(S.Ord)(RM.getMonoid(N.Eq, RS.getUnionMonoid(N.Eq)))(
                    (key, value): ReadonlyMap<number, ReadonlySet<number>> =>
                        F.pipe(
                            O.Do,
                            O.apS(
                                'key',
                                F.pipe(
                                    key,
                                    O.fromPredicate(P.not(S.isEmpty)),
                                    O.map(Number),
                                    O.filter(Number.isSafeInteger),
                                ),
                            ),
                            O.bind('value', () =>
                                F.pipe(
                                    value,
                                    RA.traverse(O.Applicative)(
                                        O.fromPredicate(Number.isSafeInteger),
                                    ),
                                    O.map(RS.fromReadonlyArray(N.Eq)),
                                ),
                            ),
                            O.match(
                                (): ReadonlyMap<number, ReadonlySet<number>> =>
                                    RM.singleton(NaN, RS.empty),
                                ({ key, value }): ReadonlyMap<number, ReadonlySet<number>> =>
                                    RM.singleton(key, value),
                            ),
                        ),
                ),
                E.fromPredicate(
                    (value) => value.has(NaN),
                    () => new Error(`${viewedStorySlidesLocalStorageKey} contains invalid data`),
                ),
            ),
        ),
        IOE.fromEither,
        IOE.chainFirstIOK((error) => () => {
            trackException(error);
        }),
        IOE.match(() => RM.empty, F.identity),
    )();

export const saveViewedStorySlides = (
    value: ReadonlyMap<number, ReadonlySet<number>>,
): IO.IO<void> =>
    F.pipe(
        value,
        RM.foldMapWithIndex(N.Ord)(RR.getMonoid(SG.first<readonly number[]>()))((key, value) => ({
            [key]: RS.toReadonlyArray(N.Ord)(value),
        })),
        E.of,
        E.chain((value) => E.tryCatch(() => JSON.stringify(value), F.identity)),
        IOE.fromEither,
        IOE.map((value) =>
            IOE.tryCatch(() => {
                localStorage.setItem(viewedStorySlidesLocalStorageKey, value);
            }, F.identity),
        ),
        IOE.chainFirstIOK((error) => () => {
            trackException(error);
        }),
        IO.map(F.constVoid),
    );
