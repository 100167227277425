import clsx from 'clsx';
import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';

import { type FC, useMemo, useRef } from 'react';

import { useUnit } from 'effector-react';

import type { NEA } from '@feip-internal/fp-ts';
import { N, ReadonlySet as RS } from '@feip-internal/fp-ts';

import { $viewedStoryIds } from '@stores/stories/model';

import { GtmEventKind, sendGtmEvent } from '@utils/analytics';
import { type HomeStoryWithSteps } from '@utils/home';

import { Container } from '@components/common/Container/Container';
import { Picture } from '@components/common/Picture/Picture';
import { DynamicStoriesSectionLightBox } from '@components/home/StoriesSectionLightBox/DynamicStoriesSectionLightBox';
import type { StoriesSectionLightBoxImperativeHandle } from '@components/home/StoriesSectionLightBox/StoriesSectionLightBox';

import styles from './StoriesSection.module.scss';

export type StoriesSectionProps = Readonly<{
    items: NEA.NonEmptyArray<HomeStoryWithSteps>;
}>;

export const StoriesSection: FC<StoriesSectionProps> = (props) => {
    const { items } = props;

    const lightBoxImperativeHandle = useRef<StoriesSectionLightBoxImperativeHandle>(null);

    const viewedStoryIds = useUnit($viewedStoryIds);

    const [viewportRef] = useEmblaCarousel(
        { align: 'start', containScroll: 'trimSnaps', dragFree: true },
        [WheelGesturesPlugin()],
    );

    return (
        <>
            {useMemo(
                () => (
                    <Container>
                        <h2 className={styles.heading}>Истории</h2>
                        <div ref={viewportRef} className={styles.viewport}>
                            <div className={styles.content}>
                                {items.map(({ id, poster }, index) => (
                                    <button
                                        key={id}
                                        type="button"
                                        className={clsx(
                                            styles.card,
                                            RS.elem(N.Eq)(id, viewedStoryIds) && styles.isViewed,
                                        )}
                                        onClick={() => {
                                            lightBoxImperativeHandle.current?.showStoryAtIndex(
                                                index,
                                            );
                                            sendGtmEvent({ kind: GtmEventKind.StoryOpened });
                                        }}
                                    >
                                        <Picture
                                            alt={`История №${index + 1}`}
                                            sources={poster}
                                            classes={{
                                                picture: styles.cardPicture,
                                                image: styles.cardImage,
                                            }}
                                        />
                                    </button>
                                ))}
                            </div>
                        </div>
                    </Container>
                ),
                [items, viewedStoryIds, viewportRef],
            )}

            <DynamicStoriesSectionLightBox
                stories={items}
                imperativeHandle={lightBoxImperativeHandle}
            />
        </>
    );
};
