import type { NextPage } from 'next';

import { useMemo } from 'react';

import { useGate, useUnit } from 'effector-react';

import { RA } from '@feip-internal/fp-ts';

import { $homeSeoData, HomeGate, homeSearchFilters, useHomeData } from '@stores/home/model';

import { doesStoryHaveSteps } from '@utils/home';

import { DefaultLayout } from '@layouts/DefaultLayout/DefaultLayout';

import { Container } from '@components/common/Container/Container';
import { FiltersForm } from '@components/common/FiltersForm/FiltersForm';
import { AboutSection } from '@components/home/AboutSection/AboutSection';
import { HomeCarousel } from '@components/home/HomeCarousel/HomeCarousel';
import { HomePromoBanner } from '@components/home/HomePromoBanner/HomePromoBanner';
import { PurchasePropertySection } from '@components/home/PurchasePropertySection/PurchasePropertySection';
import { StoriesSection } from '@components/home/StoriesSection/StoriesSection';
import { TGLogoSection } from '@components/home/TGLogoSection/TGLogoSection';
import { ProjectsBlock } from '@components/project/ProjectsBlock/ProjectsBlock';

import styles from './Home.module.scss';

export const Home: NextPage = () => {
    useGate(HomeGate);

    const seoData = useUnit($homeSeoData);

    const {
        slides,
        stories: unrefinedStories,
        projects,
        purchaseMethods,
        managingCompany,
        aboutCompany,
    } = useHomeData();

    const stories = useMemo(() => unrefinedStories.filter(doesStoryHaveSteps), [unrefinedStories]);

    const hasStories = RA.isNonEmpty(stories);
    const hasSlides = RA.isNonEmpty(slides);
    const hasProjects = RA.isNonEmpty(projects);

    return (
        <DefaultLayout seoData={seoData}>
            <div>
                {hasSlides && <HomeCarousel slides={slides} />}

                {hasStories && (
                    <section className={styles.storiesSection}>
                        <StoriesSection items={stories} />
                    </section>
                )}

                <section className={styles.filterForm}>
                    <Container>
                        <FiltersForm
                            searchFiltersModel={homeSearchFilters}
                            className={styles.filtersForm}
                            titleComponent="h1"
                        />
                    </Container>
                </section>

                {hasProjects && (
                    <section className={styles.projectsSection}>
                        <ProjectsBlock projects={projects} />
                    </section>
                )}

                <section className={styles.purchasePropertySection}>
                    <PurchasePropertySection items={purchaseMethods} />
                </section>

                <section className={styles.promoBannerSection}>
                    <HomePromoBanner data={managingCompany} />
                </section>

                <section>
                    <TGLogoSection
                        image={aboutCompany.image}
                        imagePortrait={aboutCompany.imagePortrait}
                        text={aboutCompany.description}
                    />
                </section>

                <section className={styles.aboutSection}>
                    <AboutSection data={aboutCompany} />
                </section>
            </div>
        </DefaultLayout>
    );
};
