import type { Store, StoreValue } from 'effector';
import { combine, createEvent, createStore, sample } from 'effector';
import { persist } from 'effector-storage';

import { B, F, IO, N, O, RA, RM, ReadonlySet as RS } from '@feip-internal/fp-ts';

import { $homeState, HomeGate } from '@stores/home/model';

import { storiesDomain } from './domain';
import {
    loadViewedStorySlides,
    type MarkStoryStepAsViewedEvent,
    saveViewedStorySlides,
} from './lib';

export const $viewedStorySteps = createStore<ReadonlyMap<number, ReadonlySet<number>>>(RM.empty, {
    domain: storiesDomain,
    serialize: 'ignore',
});

export const $viewedStoryIds: Store<ReadonlySet<number>> = combine(
    $homeState.map(({ stories }) => stories),
    $viewedStorySteps,
    (stories, viewedStorySteps) =>
        F.pipe(
            stories,
            RA.filterMap((story) =>
                F.pipe(
                    viewedStorySteps,
                    RM.lookup(N.Eq)(story.id),
                    O.filter((viewedStepIds) =>
                        RS.getEq(N.Eq).equals(
                            viewedStepIds,
                            F.pipe(
                                story.steps,
                                RA.foldMap(RS.getUnionMonoid(N.Eq))(({ id }) => RS.singleton(id)),
                            ),
                        ),
                    ),
                    O.isSome,
                    B.match(
                        () => O.none,
                        () => O.some(story.id),
                    ),
                ),
            ),
            RS.fromReadonlyArray(N.Eq),
        ),
);

export const markStoryStepAsViewed = createEvent<MarkStoryStepAsViewedEvent>({
    domain: storiesDomain,
});

sample({
    clock: markStoryStepAsViewed,
    source: $viewedStorySteps,
    filter: (_source, clock) =>
        Number.isSafeInteger(clock.storyId) && Number.isSafeInteger(clock.stepId),
    fn: (source, { storyId, stepId }) =>
        F.pipe(
            source,
            RM.lookup(N.Eq)(storyId),
            O.match(
                () => F.pipe(source, RM.upsertAt(N.Eq)(storyId, RS.singleton(stepId))),
                (viewedSlideIndices) =>
                    F.pipe(
                        source,
                        RM.upsertAt(N.Eq)(storyId, RS.insert(N.Ord)(stepId)(viewedSlideIndices)),
                    ),
            ),
        ),
    target: $viewedStorySteps,
});

persist({
    store: $viewedStorySteps,
    pickup: HomeGate.open,
    adapter: <State>() => ({
        get: () =>
            F.pipe(
                loadViewedStorySlides,
                IO.map(F.unsafeCoerce<StoreValue<typeof $viewedStorySteps>, State>),
            )(),
        set: (value: State) => {
            F.pipe(
                value,
                F.unsafeCoerce<State, StoreValue<typeof $viewedStorySteps>>,
                saveViewedStorySlides,
            )();
        },
    }),
});
