import * as Accordion from '@radix-ui/react-accordion';

import type { FC } from 'react';

import { applyTypograf } from '@utils/typograf';

import styles from './CardAccordion.module.scss';

import { ChevronBottomSmallIcon } from '@assets/icons';

type CardAccordionProps = {
    index: number;
    title: string;
    subtitle: string;
    description: string;
};

export const CardAccordion: FC<CardAccordionProps> = (props) => {
    const { title, subtitle, index, description } = props;
    return (
        <Accordion.Item value={String(index)} className={styles.card}>
            <Accordion.Header asChild>
                <Accordion.Trigger asChild>
                    <div className={styles.cardContent}>
                        <h4 className={styles.title}>{applyTypograf(title)}</h4>
                        <p className={styles.text}>{applyTypograf(subtitle)}</p>

                        <p className={styles.description}>{applyTypograf(description)}</p>
                        <ChevronBottomSmallIcon className={styles.arrowIcon} />
                    </div>
                </Accordion.Trigger>
            </Accordion.Header>

            <Accordion.Content className={styles.accordionContent}>
                {applyTypograf(description)}
            </Accordion.Content>
        </Accordion.Item>
    );
};
