import { match } from 'ts-pattern';

import { createEffect } from 'effector';

import { A, F, O, S } from '@feip-internal/fp-ts';

import { homeDomain } from '@stores/home/domain';

import type { HomeHeroSlide } from '@api/generated';
import type { HomePagePayload, HomePageResult } from '@api/protocol';

import { makeServerSideApiRequest } from '@utils/server-side';

import type { FetchHomePageFxPayload, FetchHomePageFxResult } from './lib';

export const fetchHomeDataFx = createEffect<FetchHomePageFxPayload, FetchHomePageFxResult>({
    domain: homeDomain,
    handler: async (payload) => {
        const { headers } = payload;

        const result = await makeServerSideApiRequest<HomePageResult>({
            headers,
            request: async (client) =>
                client.get<HomePageResult, HomePagePayload>('/api/home_page'),
        });

        const isIPhone = F.pipe(
            headers['user-agent'],
            O.fromNullable,
            O.map(F.flow(S.toLowerCase, S.includes('iphone'))),
            O.getOrElse(F.constTrue),
        );

        if (!isIPhone) {
            return result;
        }

        return {
            ...result,
            slides: F.pipe(
                result.slides,
                A.map((slide) =>
                    match(slide)
                        .with({ kind: 'image' }, F.identity)
                        .with(
                            { kind: 'video' },
                            (videoSlide): HomeHeroSlide => ({
                                kind: 'image',
                                image: {
                                    landscape: videoSlide.landscapeFallbackImage,
                                    portrait: videoSlide.portraitFallbackImage,
                                },
                                button: videoSlide.button,
                            }),
                        )
                        .exhaustive(),
                ),
            ),
        };
    },
});
