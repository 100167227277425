import * as RadixProgress from '@radix-ui/react-progress';
import clsx from 'clsx';
import { match } from 'ts-pattern';

import type { CSSProperties, FC } from 'react';

import { F, RNEA } from '@feip-internal/fp-ts';

import { clamp } from '@utils/number';

import styles from './HeroCarouselProgress.module.scss';

export type CarouselProgressProps = Readonly<{
    totalIndices: number;
    currentIndex: number;
    currentIndexProgress: number;
    onIndexClick: (index: number) => void;
    className?: string;
}>;

export const CarouselProgress: FC<CarouselProgressProps> = (props) => {
    const { totalIndices, currentIndex, currentIndexProgress, onIndexClick, className } = props;

    const normalizedCurrentIndexProgress = clamp(0, 1)(currentIndexProgress);

    const handleIndexClick = (index: number) => () => {
        onIndexClick(index);
    };

    return (
        <div className={clsx(styles.root, className)}>
            {RNEA.range(0, totalIndices - 1).map((index) => {
                const progress = match(index)
                    .when((refinedIndex) => refinedIndex < currentIndex, F.constant(1))
                    .when((refinedIndex) => refinedIndex > currentIndex, F.constant(0))
                    .otherwise(F.constant(normalizedCurrentIndexProgress));
                const style = { '--progress': progress.toFixed(3) } as CSSProperties;
                const hasHiddenProgressIndicator = index > currentIndex;

                return (
                    <button
                        key={index}
                        type="button"
                        onClick={handleIndexClick(index)}
                        className={styles.button}
                    >
                        <RadixProgress.Root value={progress} max={1} className={styles.progress}>
                            <RadixProgress.Indicator
                                style={style}
                                className={clsx(
                                    styles.progressIndicator,
                                    hasHiddenProgressIndicator && styles.isTransparent,
                                )}
                            />
                        </RadixProgress.Root>
                    </button>
                );
            })}
        </div>
    );
};
