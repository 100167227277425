import type { ComponentProps, ComponentPropsWithoutRef, FC } from 'react';
import { useMemo } from 'react';

import { Eq, F, I, O, R, RA, RM, RNEA, S, SG } from '@feip-internal/fp-ts';

import type { ImagePair, ImageSet, ImageSetWeb, ImageUrl } from '@api/generated';
import { ImageFormat } from '@api/generated';

type ImageSize = Exclude<keyof ImageSetWeb, 'blur'>;

type ImageSourceDescription = Readonly<{
    key: string;
    sourcesSet: string;
    mediaQuery: string;
    mimeFormat: string;
    width: number;
    height: number;
}>;

const ImageFormatEq = Eq.contramap(F.identity<ImageFormat>)(S.Eq);

const imageSizeToMinWidthMap: Record<ImageSize, number> = {
    small: 0,
    medium: 640,
    large: 900,
};

const imageFormatToMimeFormatMap: Record<ImageFormat, string> = {
    avif: 'image/avif',
    // jpg: 'image/jpg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    // Sic! Check https://www.iana.org/assignments/media-types/media-types.xhtml#image
    svg: 'image/svg+xml',
    webp: 'image/webp',
};

const imageFormatsInPreferredOrder: readonly ImageFormat[] = [
    ImageFormat.Svg,
    ImageFormat.Avif,
    ImageFormat.Webp,
    ImageFormat.Png,
    ImageFormat.Jpeg,
];

const getImageUrlsByTypeMap = (urls: readonly ImageUrl[]): ReadonlyMap<ImageFormat, string> =>
    F.pipe(
        ({ format, url }: ImageUrl) => RM.singleton(format, url),
        RA.foldMap(RM.getMonoid(ImageFormatEq, SG.last<string>())),
        I.ap(urls),
    );

const describeSources = ({ web }: ImageSet): readonly ImageSourceDescription[] =>
    F.pipe(
        web,
        O.fromNullable,
        O.map(({ small, medium, large }) => ({
            small,
            medium,
            large,
        })),
        O.map(
            F.pipe(
                (size: ImageSize, [lowDpiImage, highDpiImage]: ImagePair) =>
                    F.pipe(
                        I.Do,
                        I.apS('lowDpiImagesByType', getImageUrlsByTypeMap(lowDpiImage.urls)),
                        I.apS('highDpiImagesByType', getImageUrlsByTypeMap(highDpiImage.urls)),
                        ({ lowDpiImagesByType, highDpiImagesByType }) =>
                            F.pipe(
                                (format: ImageFormat): readonly ImageSourceDescription[] =>
                                    F.pipe(
                                        O.Do,
                                        O.bind('lowDpiUrl', () =>
                                            RM.lookup(ImageFormatEq)(format)(lowDpiImagesByType),
                                        ),
                                        O.bind('highDpiUrl', () =>
                                            RM.lookup(ImageFormatEq)(format)(highDpiImagesByType),
                                        ),
                                        O.map(
                                            ({
                                                highDpiUrl,
                                                lowDpiUrl,
                                            }): ImageSourceDescription => ({
                                                height: lowDpiImage.height,
                                                width: lowDpiImage.width,
                                                key: `${size}:${format}`,
                                                sourcesSet: `${highDpiUrl} 2x, ${lowDpiUrl}`,
                                                mediaQuery: `(min-width: ${imageSizeToMinWidthMap[size]}px)`,
                                                mimeFormat: imageFormatToMimeFormatMap[format],
                                            }),
                                        ),
                                        RA.fromOption,
                                    ),
                                RA.foldMap(RA.getMonoid<ImageSourceDescription>()),
                                I.ap(imageFormatsInPreferredOrder),
                            ),
                    ),
                R.foldMapWithIndex(S.Ord)(RA.getMonoid<ImageSourceDescription>()),
            ),
        ),
        O.getOrElseW(F.constant([])),
    );

export type PictureProps = Readonly<{
    sources: ImageSet;
    alt: string;
    classes?: Classes<'picture' | 'image'>;
    isLazy?: boolean;
    pictureProps?: ComponentPropsWithoutRef<'picture'>;
    imgProps?: ComponentProps<'img'>;
}>;

export const Picture: FC<PictureProps> = (props) => {
    const { sources, classes, alt, pictureProps, imgProps, isLazy = true } = props;

    const describedSources = useMemo(() => describeSources(sources), [sources]);

    if (!RA.isNonEmpty(describedSources)) return null;

    const [rest, last] = RNEA.unappend(describedSources);

    return (
        <picture className={classes?.picture} {...pictureProps}>
            {rest.map(({ key, mimeFormat, sourcesSet, mediaQuery, width, height }) => (
                <source
                    key={key}
                    srcSet={sourcesSet}
                    media={mediaQuery}
                    type={mimeFormat}
                    width={width}
                    height={height}
                />
            ))}
            <img
                className={classes?.image}
                srcSet={last.sourcesSet}
                alt={alt}
                loading={isLazy ? 'lazy' : 'eager'}
                width={last.width}
                height={last.height}
                {...imgProps}
            />
        </picture>
    );
};
