import clsx from 'clsx';

import type { FC } from 'react';

import type { RNEA } from '@feip-internal/fp-ts';

import type { HomeHeroSlide } from '@api/generated';

import { makeSearchRoute } from '@utils/route';

import { ButtonLink } from '@components/common/Button/Button';
import { HeroCarousel } from '@components/hero-carousel/HeroCarousel/HeroCarousel';

import styles from './HomeCarousel.module.scss';

import { ChevronRightBoldIcon } from '@assets/icons';

export type HomeCarouselProps = Readonly<{ slides: RNEA.ReadonlyNonEmptyArray<HomeHeroSlide> }>;

export const HomeCarousel: FC<HomeCarouselProps> = (props) => {
    const { slides } = props;

    return (
        <>
            <HeroCarousel slides={slides} className={styles.carousel} />
            <ButtonLink
                variant="transparentOutline"
                iconAfter={ChevronRightBoldIcon}
                href={makeSearchRoute()}
                classes={{ iconAfter: styles.icon }}
                className={clsx(styles.button, styles.mobile)}
            >
                Подбор квартиры
            </ButtonLink>
        </>
    );
};
