import clsx from 'clsx';

import type { FC, ReactNode } from 'react';

import type { ImageSet } from '@api/generated';

import { ButtonLink } from '@components/common/Button/Button';
import type { ButtonLinkProps, ButtonProps } from '@components/common/Button/Button';
import { Button } from '@components/common/Button/Button';
import { Container } from '@components/common/Container/Container';
import { Picture } from '@components/common/Picture/Picture';

import styles from './PromoBanner.module.scss';

export type PromoBannerProps = Readonly<{
    image: ImageSet;
    portraitImage?: ImageSet;
    title: ReactNode;
    text: string | null;
    isTextHiddenOnMobile?: boolean;
    buttons?: ButtonProps[];
    links?: ButtonLinkProps[];
    className?: string;
}>;

export const PromoBanner: FC<PromoBannerProps> = (props) => {
    const {
        image,
        portraitImage,
        title,
        text,
        buttons,
        links,
        isTextHiddenOnMobile = false,
    } = props;

    const hasButtonsOrLinks =
        (buttons !== undefined && buttons.length > 0) || (links !== undefined && links.length > 0);
    const hasDescription = text !== null;

    return (
        <Container>
            <div className={styles.root}>
                <Picture alt="" sources={image} classes={{ image: styles.image }} />
                <Picture
                    alt=""
                    sources={portraitImage ?? image}
                    classes={{ image: styles.portraitImage }}
                />

                <div className={styles.content}>
                    <h5 className={styles.title}>{title}</h5>

                    {hasDescription && (
                        <p
                            className={clsx(
                                styles.text,
                                isTextHiddenOnMobile && styles.isHiddenOnMobile,
                            )}
                        >
                            {text}
                        </p>
                    )}

                    {hasButtonsOrLinks && (
                        <div
                            className={clsx(
                                styles.buttons,
                                (buttons !== undefined || links !== undefined) &&
                                    (buttons?.length ?? 0) + (links?.length ?? 0) > 1 &&
                                    styles.isFullOnMobile,
                            )}
                        >
                            {links !== undefined &&
                                links.map((buttonProps, index) => (
                                    <ButtonLink key={index} {...buttonProps} />
                                ))}
                            {buttons !== undefined &&
                                buttons.map((buttonProps, index) => (
                                    <Button key={index} {...buttonProps} />
                                ))}
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
};
