import clsx from 'clsx';
import type { EmblaCarouselType } from 'embla-carousel';

import type { FC } from 'react';
import { useCallback } from 'react';

import type { ClassNameMap } from '@domains/shared';

import styles from './CarouselButtons.module.scss';

import { ChevronLeftIcon, ChevronRightIcon } from '@assets/icons';

type ClassKey = 'buttons' | 'prev' | 'next';
export type NavigationButtonVariant = 'default' | 'round' | 'outside';

const buttonVariantClassMap: ClassNameMap<NavigationButtonVariant> = {
    default: styles.isDefault,
    round: styles.isRound,
    outside: styles.isOutside,
};

type CarouselProps = {
    embla: EmblaCarouselType | undefined;
    classes?: Classes<ClassKey>;
    variant?: NavigationButtonVariant;
    onClick?: () => void;
};
export const CarouselButtons: FC<CarouselProps> = (props) => {
    const { embla, classes, onClick, variant = 'default' } = props;

    const scrollPrev = useCallback(() => {
        embla?.scrollPrev();
        onClick?.();
    }, [embla, onClick]);

    const scrollNext = useCallback(() => {
        embla?.scrollNext();
        onClick?.();
    }, [embla, onClick]);

    return (
        <>
            <button
                type="button"
                className={clsx(
                    styles.button,
                    buttonVariantClassMap[variant],
                    classes?.buttons,
                    classes?.prev,
                )}
                onClick={scrollPrev}
            >
                <span className={styles.buttonContent}>
                    <ChevronLeftIcon className={styles.icon} />
                </span>
            </button>

            <button
                type="button"
                className={clsx(
                    styles.button,
                    buttonVariantClassMap[variant],
                    classes?.buttons,
                    classes?.next,
                )}
                onClick={scrollNext}
            >
                <span className={styles.buttonContent}>
                    <ChevronRightIcon className={styles.icon} />
                </span>
            </button>
        </>
    );
};
