/* eslint-disable @typescript-eslint/no-shadow */
import clsx from 'clsx';
import type { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { match } from 'ts-pattern';

import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { F, N, O, RM, type RNEA } from '@feip-internal/fp-ts';

import type { EitherSlide, HomeHeroSlide } from '@api/generated';

import { trackException } from '@utils/sentry';

import { type CarouselButtonProps } from '@components/hero-carousel/HeroCarouselButton/HeroCarouselButton';
import { HeroCarouselImageSlide } from '@components/hero-carousel/HeroCarouselImageSlide/HeroCarouselImageSlide';
import { CarouselProgress } from '@components/hero-carousel/HeroCarouselProgress/HeroCarouselProgress';
import { HeroCarouselVideoSlide } from '@components/hero-carousel/HeroCarouselVideoSlide/HeroCarouselVideoSlide';
import { CarouselButtons } from '@components/slider/CarouselButtons/CarouselButtons';

import styles from './HeroCarousel.module.scss';

enum SlideStateKind {
    Uninitialized = 'uninitialized',
    Image = 'image',
    Video = 'video',
}

type SlideStateBase<T extends SlideStateKind> = Readonly<{
    kind: T;
    index: number;
}>;

type SlideStateUninitialized = SlideStateBase<SlideStateKind.Uninitialized>;

type SlideStateImage = SlideStateBase<SlideStateKind.Image> &
    Readonly<{
        isLoaded: boolean;
        duration: number;
        currentTime: number;
    }>;

type SlideStateVideo = SlideStateBase<SlideStateKind.Video> &
    Readonly<{
        isLoaded: boolean;
        duration: number;
        currentTime: number;
        requestedIterationCount: number;
        currentIterationCount: number;
    }>;

type SlideState = SlideStateUninitialized | SlideStateImage | SlideStateVideo;

const imageProgressDuration = 4000; // milliseconds
const tickDuration = 20; // milliseconds
const videoIterationCount = 4; // times

const useSlideState = (
    slides: RNEA.ReadonlyNonEmptyArray<EitherSlide>,
    embalApi: EmblaCarouselType | undefined,
) => {
    const videoRefs = useRef<ReadonlyMap<number, HTMLVideoElement>>(RM.empty);
    const imageRefs = useRef<ReadonlyMap<number, HTMLImageElement>>(RM.empty);
    const portraitImageRefs = useRef<ReadonlyMap<number, HTMLImageElement>>(RM.empty);

    const [state, setState] = useState<SlideState>({
        kind: SlideStateKind.Uninitialized,
        index: 0,
    });

    const play = useCallback(
        (index: number, shouldScroll = true) => {
            const slide = slides[index];

            switch (slide?.kind) {
                case SlideStateKind.Image: {
                    const image = imageRefs.current.get(index);
                    const portraitImage = portraitImageRefs.current.get(index);

                    if (image === undefined || portraitImage === undefined) {
                        return;
                    }

                    videoRefs.current.get(state.index)?.pause();

                    setState({
                        kind: SlideStateKind.Image,
                        index,
                        isLoaded: image.complete || portraitImage.complete,
                        duration: imageProgressDuration,
                        currentTime: 0,
                    });

                    embalApi?.scrollTo(index);

                    break;
                }
                case SlideStateKind.Video: {
                    const video = videoRefs.current.get(index);

                    if (video === undefined) {
                        return;
                    }

                    videoRefs.current.get(state.index)?.pause();

                    const canPlay = video.readyState >= 4;

                    setState({
                        kind: SlideStateKind.Video,
                        index,
                        isLoaded: canPlay,
                        duration: canPlay ? video.duration : 0,
                        currentTime: 0,
                        requestedIterationCount: slide.loopVideo ? videoIterationCount : 1,
                        currentIterationCount: 0,
                    });

                    if (canPlay) {
                        video.currentTime = 0;
                        video.play().catch(trackException);
                    }

                    if (shouldScroll) {
                        embalApi?.scrollTo(index);
                    }

                    break;
                }
                default:
                    break;
            }
        },
        [embalApi, slides, state.index],
    );

    const tick = useCallback(() => {
        if (state.kind === SlideStateKind.Image && state.isLoaded) {
            const newCurrentTime = Math.min(
                state.currentTime + tickDuration,
                imageProgressDuration,
            );

            if (newCurrentTime === imageProgressDuration) {
                play(state.index !== slides.length - 1 ? state.index + 1 : 0);
            } else {
                setState((state) => ({ ...state, currentTime: newCurrentTime }));
            }
        }
    }, [play, slides.length, state]);

    useEffect(() => {
        const interval = setInterval(tick, tickDuration);

        return () => {
            clearInterval(interval);
        };
    }, [tick]);

    useEffect(() => {
        play(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slides]);

    const currentIndex = state.index;
    const currentIndexProgress = F.pipe(
        match(state)
            .with({ kind: SlideStateKind.Image }, (slide) => slide.currentTime / slide.duration)
            .with(
                { kind: SlideStateKind.Video },
                (slide) =>
                    (slide.currentIterationCount + slide.currentTime / slide.duration) /
                    slide.requestedIterationCount,
            )
            .otherwise(F.constant(0)),
        O.fromPredicate(Number.isFinite),
        O.getOrElse(F.constant(0)),
    );

    const imageRef =
        (index: number) =>
        (image: HTMLImageElement | null): void => {
            imageRefs.current = F.pipe(
                imageRefs.current,
                image === null ? RM.deleteAt(N.Eq)(index) : RM.upsertAt(N.Eq)(index, image),
            );
        };

    const portraitImageRef =
        (index: number) =>
        (image: HTMLImageElement | null): void => {
            portraitImageRefs.current = F.pipe(
                portraitImageRefs.current,
                image === null ? RM.deleteAt(N.Eq)(index) : RM.upsertAt(N.Eq)(index, image),
            );
        };

    const videoRef =
        (index: number) =>
        (video: HTMLVideoElement | null): void => {
            if (video !== null) {
                // NOTE:
                // 1. Due to a bug in Safari (Which Apple considers a feature),
                // videos are not playing automatically unless they are muted.
                // 2. Due to react bug, "muted" attribute is not present in DOM, so we have to place
                // it there manually.
                video.muted = true;
            }
            videoRefs.current = F.pipe(
                videoRefs.current,
                video === null ? RM.deleteAt(N.Eq)(index) : RM.upsertAt(N.Eq)(index, video),
            );
        };

    const handleImageLoad = (index: number) => () => {
        if (state.kind === SlideStateKind.Image && state.index === index && !state.isLoaded) {
            setState((state) => ({ ...state, isLoaded: true }));
        }
    };

    const handleVideoCanPlay = (index: number) => () => {
        if (state.kind !== SlideStateKind.Video || state.index !== index || state.isLoaded) {
            return;
        }

        const video = videoRefs.current.get(index);

        if (video === undefined) {
            return;
        }

        setState((state) => ({
            ...state,
            isLoaded: true,
            duration: video.duration,
            currentTime: video.currentTime,
        }));

        video.play().catch(trackException);
    };

    const handleImageError = (index: number) => () => {
        play(index !== slides.length - 1 ? index + 1 : 0);
    };

    const handleVideoError = (index: number) => () => {
        play(index !== slides.length - 1 ? index + 1 : 0);
    };

    const handleVideoTimeUpdate = (index: number) => (newCurrentTime: number) => {
        if (state.kind !== SlideStateKind.Video || state.index !== index) {
            return;
        }

        setState({ ...state, currentTime: Math.min(newCurrentTime, state.duration) });
    };

    const handleVideoEnded = (index: number) => () => {
        if (state.kind !== SlideStateKind.Video || state.index !== index) {
            return;
        }

        const newIterationCount = state.currentIterationCount + 1;

        if (newIterationCount === state.requestedIterationCount) {
            play(state.index !== slides.length - 1 ? state.index + 1 : 0);
        } else {
            videoRefs.current.get(index)?.play().catch(trackException);

            setState((state) => ({ ...state, currentIterationCount: newIterationCount }));
        }
    };

    return {
        currentIndex,
        currentIndexProgress,
        imageRef,
        portraitImageRef,
        videoRef,
        play,
        handleImageLoad,
        handleVideoCanPlay,
        handleImageError,
        handleVideoError,
        handleVideoTimeUpdate,
        handleVideoEnded,
    };
};

export type CarouseContent = Readonly<{
    title?: ReactNode;
    controls?: readonly CarouselButtonProps[];
}>;

export type HeroCarouselProps = Readonly<{
    slides: RNEA.ReadonlyNonEmptyArray<HomeHeroSlide>;
    content?: CarouseContent;
    className?: string;
}>;

export const HeroCarousel: FC<HeroCarouselProps> = (props) => {
    const { slides, content, className } = props;

    const [viewportRef, embla] = useEmblaCarousel({
        loop: true,
        containScroll: 'keepSnaps',
    });
    const slidesState = useSlideState(slides, embla);
    const play = slidesState.play;

    const handleScrollTo = useCallback(
        (index: number) => {
            play(index);
            const autoplay = embla?.plugins().autoplay;
            if (autoplay !== undefined) {
                autoplay.reset();
            }
        },
        [embla, play],
    );

    const resetAutoplay = () => {
        const autoplay = embla?.plugins().autoplay;
        if (autoplay !== undefined) {
            autoplay.reset();
        }
    };

    const onSelect = useCallback(
        (emblaApi: EmblaCarouselType) => {
            handleScrollTo(emblaApi.selectedScrollSnap());
        },
        [handleScrollTo],
    );

    useEffect(() => {
        if (embla !== undefined) {
            onSelect(embla);
            embla.on('reInit', onSelect);
            embla.on('select', onSelect);
        }
    }, [embla, onSelect]);

    return (
        <div className={clsx(styles.root, className)}>
            <div ref={viewportRef} className={styles.viewport}>
                <div className={styles.container}>
                    {slides.map((item, index) =>
                        match(item)
                            .with({ kind: 'image' }, (image) => (
                                <HeroCarouselImageSlide
                                    key={index}
                                    image={image}
                                    imageRef={slidesState.imageRef(index)}
                                    portraitImageRef={slidesState.portraitImageRef(index)}
                                    classes={{
                                        picture: styles.picture,
                                        image: styles.image,
                                        data: styles.data,
                                        title: styles.title,
                                        button: styles.button,
                                    }}
                                    onLoad={slidesState.handleImageLoad(index)}
                                    onError={slidesState.handleImageError(index)}
                                    button={image.button}
                                    title={content?.title ?? null}
                                />
                            ))
                            .with({ kind: 'video' }, (video) => (
                                <HeroCarouselVideoSlide
                                    key={index}
                                    video={video}
                                    videoRef={slidesState.videoRef(index)}
                                    classes={{
                                        video: styles.video,
                                        data: styles.data,
                                        title: styles.title,
                                        button: styles.button,
                                    }}
                                    onError={slidesState.handleVideoError(index)}
                                    onTimeUpdate={slidesState.handleVideoTimeUpdate(index)}
                                    onEnded={slidesState.handleVideoEnded(index)}
                                    onCanPlay={slidesState.handleVideoCanPlay(index)}
                                    button={video.button}
                                    title={content?.title ?? null}
                                />
                            ))
                            .exhaustive(),
                    )}
                </div>
            </div>
            <CarouselButtons variant="round" embla={embla} onClick={resetAutoplay} />

            <CarouselProgress
                className={styles.progress}
                totalIndices={slides.length}
                currentIndex={slidesState.currentIndex}
                currentIndexProgress={slidesState.currentIndexProgress}
                onIndexClick={slidesState.play}
            />
        </div>
    );
};
