import clsx from 'clsx';

import type { FC, ReactNode, Ref } from 'react';

import type { EitherSlideImage, HomeHeroSlideButton } from '@api/generated';

import { ButtonLink } from '@components/common/Button/Button';
import { Picture } from '@components/common/Picture/Picture';

import styles from './HeroCarouselImageSlide.module.scss';

export type HeroSliderImageSlideClasses = Classes<
    'picture' | 'image' | 'data' | 'title' | 'button'
>;

export type HeroCarouselImageSlideProps = Readonly<{
    image: EitherSlideImage;
    imageRef: Ref<HTMLImageElement>;
    portraitImageRef: Ref<HTMLImageElement>;
    classes: HeroSliderImageSlideClasses;
    onLoad: () => void;
    onError: () => void;
    button: HomeHeroSlideButton | null;
    title?: ReactNode;
}>;

export const HeroCarouselImageSlide: FC<HeroCarouselImageSlideProps> = (props) => {
    const { image, imageRef, portraitImageRef, onLoad, onError, classes, button, title } = props;

    const hasTitle = title !== null;
    const hasButton = button !== null;

    return (
        <div className={styles.root} onLoad={onLoad} onError={onError}>
            <Picture
                sources={image.image.landscape}
                alt="Слайд"
                classes={{ picture: clsx(classes.picture, styles.picture), image: classes.image }}
                imgProps={{ ref: imageRef }}
            />
            <Picture
                sources={image.image.portrait}
                alt="Слайд"
                classes={{
                    picture: clsx(classes.picture, styles.portraitPicture),
                    image: classes.image,
                }}
                imgProps={{ ref: portraitImageRef }}
            />
            <div className={classes.data}>
                {hasTitle && <h2 className={classes.title}>{title}</h2>}
                {hasButton && (
                    <ButtonLink
                        href={button.url}
                        variant="transparentOutline"
                        className={classes.button}
                    >
                        {button.text}
                    </ButtonLink>
                )}
            </div>
        </div>
    );
};
