import Router from 'next/router';
import { spread } from 'patronum';

import { createEffect, createStore, sample } from 'effector';
import { createGate, useUnit } from 'effector-react';

import { F, Predicate } from '@feip-internal/fp-ts';

import { clearSessionEv } from '@stores/app';
import { homeDomain } from '@stores/home/domain';
import { fetchHomeDataFx } from '@stores/home/server-side';
import { createSearchFiltersModel } from '@stores/search/factory';

import type { HomeResult, SearchFilters } from '@api/generated';

import { makeSearchRoute } from '@utils/route';
import { serializeSearchFiltersToQuery } from '@utils/search';
import { emptySeoData } from '@utils/seo';

import { mockEmptyImageSet } from '@mock/image';

// --- Constants ---

const initialHomeState: HomeResult = {
    slides: [],
    stories: [],
    searchFilters: {
        advantages: [],
        area: {
            kind: 'float',
            availableMin: 0,
            availableMax: 0,
            selectedMin: 0,
            selectedMax: 0,
        },
        completionYear: [],
        locality: [],
        price: {
            kind: 'integer',
            availableMin: 0,
            availableMax: 0,
            selectedMin: 0,
            selectedMax: 0,
        },
        project: [],
        roomsNumber: [],
    },
    projects: [],
    purchaseMethods: [],
    managingCompany: {
        title: '',
        image: {
            landscape: mockEmptyImageSet,
            portrait: mockEmptyImageSet,
        },
        url: '',
        description: null,
    },
    aboutCompany: {
        title: '',
        description: '',
        image: mockEmptyImageSet,
        imagePortrait: mockEmptyImageSet,
    },
    seo: emptySeoData,
};

// --- Gates ---

export const HomeGate = createGate<void>({ domain: homeDomain });

// --- Units ---

export const $homeState = createStore<HomeResult>(initialHomeState, { domain: homeDomain })
    .on(fetchHomeDataFx.doneData, F.SK)
    .reset([HomeGate.close, clearSessionEv]);

export const $homeSeoData = $homeState.map(({ seo }) => seo);

const applyFiltersFx = createEffect<SearchFilters, void>({
    domain: homeDomain,
    handler: async (payload) => {
        await Router.push({
            pathname: makeSearchRoute(),
            query: serializeSearchFiltersToQuery(payload),
        });
    },
});

const resetFiltersFx = createEffect<void, boolean>({ domain: homeDomain, handler: F.constTrue });

export const homeSearchFilters = createSearchFiltersModel(
    homeDomain,
    applyFiltersFx,
    resetFiltersFx,
);

spread({
    source: fetchHomeDataFx.doneData.map((state) => ({
        homeState: state,
        filters: state.searchFilters,
    })),
    targets: { homeState: $homeState, filters: homeSearchFilters.initialize },
});

sample({
    clock: HomeGate.status,
    fn: Predicate.not(F.identity<boolean>),
    target: homeSearchFilters.clear,
});

// --- Hooks ---

export const useHomeData = (): HomeResult => useUnit($homeState);
