import type { Feature } from '@yandex/ymaps3-clusterer';
import type { LngLat, YMap as YMapInstance, YMapLocationRequest } from '@yandex/ymaps3-types';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import {
    YMap,
    YMapControlButton,
    YMapControls,
    YMapCustomClusterer,
    YMapDefaultFeaturesLayer,
    YMapDefaultSchemeLayer,
    YMapMarker,
    YMapZoomControl,
} from 'ymap3-components';

import { useCallback, useEffect, useRef, useState } from 'react';

import { makeProjectRoute } from '@utils/route';
import { trackException } from '@utils/sentry';

import type { Coordinate } from '@components/common/ProjectsList/components/AsMap/lib';
import { getCenterAndZoom } from '@components/common/ProjectsList/components/AsMap/lib';
import type { ModeContent } from '@components/common/ProjectsList/lib';

import styles from './AsMap.module.scss';

import { ArrowsPinchIcon, ArrowsSpreadIcon, MapMarker } from '@assets/icons';

export const AsMap: ModeContent = ({ projects }) => {
    const rootRef = useRef<HTMLDivElement | null>(null);
    const instance = useRef<YMapInstance | null>(null);

    const [isFullscreen, setIsFullscreen] = useState(false);

    const { push } = useRouter();

    const points: Feature[] = projects.map((project, index) => ({
        type: 'Feature',
        id: String(index),
        geometry: {
            type: 'Point',
            coordinates: [project.coordinates.longitude, project.coordinates.latitude] as LngLat,
        },
        properties: { title: project.title, slug: project.slug },
    }));

    const [location, setLocation] = useState<YMapLocationRequest | null>(null);

    useEffect(() => {
        const { current: root } = rootRef;
        if (root === null) return;
        const { clientWidth, clientHeight } = root;
        setLocation(
            getCenterAndZoom(
                projects.map<Coordinate>(({ coordinates: { latitude, longitude } }) => [
                    longitude,
                    latitude,
                ]),
                { width: clientWidth, height: clientHeight },
            ),
        );
    }, [projects]);

    useEffect(() => {
        const onFullscreenChange = () => {
            setIsFullscreen(document.fullscreenElement !== null);
        };
        document.addEventListener('fullscreenchange', onFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', onFullscreenChange);
        };
    }, []);

    const marker = useCallback(
        (feature: Feature) => (
            <YMapMarker
                coordinates={feature.geometry.coordinates}
                onClick={() => {
                    push(makeProjectRoute(String(feature.properties?.slug))).catch(trackException);
                }}
            >
                <div className={styles.marker}>
                    <MapMarker className={styles.markerIcon} />
                    <p className={styles.markerText}>{String(feature.properties?.title)}</p>
                </div>
            </YMapMarker>
        ),
        [push],
    );

    const cluster = useCallback(
        (coordinates: LngLat, features: Feature[]) => (
            <YMapMarker key={features.map(({ id }) => id).join('-')} coordinates={coordinates}>
                <div className={styles.cluster}>
                    <p className={styles.clusterText}>{features.length}</p>
                </div>
            </YMapMarker>
        ),
        [],
    );

    return (
        <div ref={rootRef} className={clsx(styles.map, isFullscreen && styles.isExpanded)}>
            {location !== null && (
                <YMap
                    ref={instance}
                    behaviors={[
                        'drag',
                        'pinchZoom',
                        // 'scrollZoom',
                        'dblClick',
                        'magnifier',
                        'oneFingerZoom',
                        'mouseRotate',
                        'mouseTilt',
                        'pinchRotate',
                        'panTilt',
                    ]}
                    {...{ location }}
                >
                    <YMapDefaultSchemeLayer />
                    <YMapDefaultFeaturesLayer />
                    {document.fullscreenEnabled && (
                        <YMapControls position="top right">
                            <YMapControlButton
                                onClick={() => {
                                    if (isFullscreen) {
                                        void document.exitFullscreen();
                                    } else {
                                        void instance.current?.container.requestFullscreen();
                                    }
                                }}
                            >
                                <div className={styles.fullscreenIcon}>
                                    {isFullscreen ? <ArrowsPinchIcon /> : <ArrowsSpreadIcon />}
                                </div>
                            </YMapControlButton>
                        </YMapControls>
                    )}
                    <YMapControls position="right">
                        <YMapZoomControl />
                    </YMapControls>
                    <YMapCustomClusterer
                        marker={marker}
                        cluster={cluster}
                        gridSize={320}
                        features={points}
                    />
                </YMap>
            )}
        </div>
    );
};
