import type { FC } from 'react';

import type { RNEA } from '@feip-internal/fp-ts';

import type { ProjectPreview } from '@api/generated';

import { Container } from '@components/common/Container/Container';
import { ProjectsList } from '@components/common/ProjectsList/ProjectsList';

import styles from './ProjectsBlock.module.scss';

export type ProjectsBlockProps = Readonly<{
    projects: RNEA.ReadonlyNonEmptyArray<ProjectPreview>;
}>;

export const ProjectsBlock: FC<ProjectsBlockProps> = ({ projects }) => (
    <Container classes={{ root: styles.root }}>
        <h3 className={styles.heading}>Наши проекты во&nbsp;Владивостоке</h3>
        <ProjectsList projects={projects} showInList={2} />
    </Container>
);
