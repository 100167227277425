import clsx from 'clsx';

import type { FC, ReactNode } from 'react';

import type { ImageSet } from '@api/generated';

import { Picture } from '@components/common/Picture/Picture';

import styles from './TGLogoSection.module.scss';

export const TGLogoSection: FC<
    {
        image: ImageSet;
        imagePortrait: ImageSet;
        text?: ReactNode;
    } & ClassesProp<'image'>
> = ({ image, imagePortrait, text, classes }) => {
    const imageClassnames = clsx(styles.image, classes?.image);

    return (
        <div className={styles.root}>
            <Picture
                alt=""
                sources={image}
                classes={{ picture: styles.picture, image: imageClassnames }}
                isLazy={false}
            />
            <Picture
                alt=""
                sources={imagePortrait}
                classes={{ picture: styles.portraitPicture, image: imageClassnames }}
                isLazy={false}
            />

            <div className={styles.content}>
                <p className={styles.text}>{text}</p>
            </div>
        </div>
    );
};
